//import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {
  return (
    <div className="App">
      
	  <div class="logo">
		<img src="/logo512.png" alt="Flamboyan Kitchen Logo"/>
		<div class="textOver">
			Coming Soon
		</div>
	  </div>
	  
	  
	  
	  <div class="socials">
		<div>
			<a href="https://www.instagram.com/flamboyanbx/">
			<img src="/Instagram_Glyph_Black.png" width="30" height="30"/>
			@flamboyanbx
			</a>
		</div>
		<div>
			<a href="https://www.facebook.com/profile.php?id=100089455321064&mibextid=ZbWKwL">
			<img src="/f_logo_RGB-Black_58.png" width="30" height="30"/>
			Flamboyan Kitchen
			</a>
		</div>
		<div>flamboyanbx@gmail.com</div>
	  </div>
	  
    </div>
  );
}

export default App;
